<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Graduate students</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Graduate students
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div
              class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start"
            >
              <div class="row">
                <v-col cols="12" md="2">
                  <v-text-field
                    class="form-control"
                    outlined
                    clearable
                    v-model="search.name"
                    dense
                    v-on:keyup.enter="getAcademicClasses()"
                    label="Class name"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-select
                    class="form-control"
                    outlined
                    clearable
                    v-model="search.academic_year"
                    dense
                    v-on:keyup.enter="getAcademicClasses()"
                    :items="academicYears"
                    item-text="title"
                    item-value="id"
                    label="Batch"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" md="2">
                  <v-select
                    class="form-control"
                    outlined
                    clearable
                    :items="programs"
                    v-model="search.program_id"
                    @change="getGrades"
                    dense
                    v-on:keyup.enter="getAcademicClasses()"
                    item-text="title"
                    item-value="id"
                    label="Program"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" md="2">
                  <v-select
                    class="form-control"
                    outlined
                    clearable
                    :items="gradesLevels"
                    v-model="search.grade_id"
                    dense
                    item-text="title"
                    item-value="id"
                    v-on:keyup.enter="getAcademicClasses()"
                    label="Semester/year"
                  >
                  </v-select>
                </v-col>

                <v-col cols="12" md="2">
                  <v-select
                    class="form-control"
                    outlined
                    clearable
                    :items="[
                      { text: 'Morning', value: 'morning' },
                      { text: 'Day', value: 'day' },
                    ]"
                    v-model="search.shift"
                    item-text="text"
                    item-value="value"
                    dense
                    v-on:keyup.enter="getAcademicClasses()"
                    label="Shift"
                  >
                  </v-select>
                </v-col>

                <v-col cols="12" md="2">
                  <v-btn
                    @click.prevent="getAcademicClasses()"
                    class="btn btn-primary btn-block w-35 float-right"
                    :loading="loading"
                    dark
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="">
              <v-skeleton-loader type="table-thead" v-if="loading"> </v-skeleton-loader>

              <v-skeleton-loader v-if="loading" type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table" v-if="!loading">
                <thead>
                  <tr class="px-3">
                    <th class="px-3 wrap-column">Classroom</th>
                    <th class="px-3 wrap-column">Class date range</th>
                    <th class="px-3 text-center">Action</th>
                  </tr>
                </thead>
                <template>
                  <tr v-for="(academicClass, index) of academic_classes" :key="index">
                    <td class="px-3 wrap-column">
                      <router-link
                          :to="{
                          name: 'academic-class-summary',
                          params: {
                            classId: academicClass.id,
                          },
                        }"
                      >
                        <simplebold>{{ academicClass.title }}</simplebold> </router-link>
                      <span class="badge-dot" v-bind:class="{ 'bg-color-green': academicClass.is_active, 'bg-color-red': !academicClass.is_active }"></span> <br/>
                      <simplebold>{{ academicClass.unique_no ? academicClass.unique_no : "" }} </simplebold> |
                      <simplebold>{{ academicClass.shift.toUpperCase() }}</simplebold> | <span class="count_number_badge">{{ academicClass.total_student }}</span> <br/>

                    </td>
                    <td class="px-3 wrap-column">
                      <simplebold>{{academicClass.program.short_title != "" ? academicClass.program.short_title : academicClass.program.title }}</simplebold> |
                      <simplebold>{{ academicClass.grade.title }}</simplebold> |
                      <simplebold>{{ academicClass.academic_year.title }}</simplebold> <br/>
                      <span>
                        <i class="fa-solid fa-square-check text-green mr-2"  v-if="academicClass.is_confirmed"></i>
                        <i class="fa-solid fa-rectangle-xmark  mr-2 text-red" v-else></i>
                        <i class="fa-solid fa-stopwatch text-green mr-2" v-if="!academicClass.is_complete"></i>
                        <i class="fa-solid fa-lock text-red  mr-2" v-else></i>
                      </span>
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                          size="sm"
                          variant="link"
                          toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                          no-caret
                          right
                          no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item"  v-if="checkIsAccessible('academic-class', 'list')">
                              <a class="navi-link" @click="openDialog(academicClass.id)">
                                <span class="navi-icon">
                                  <i class="fas fa-graduation-cap"></i>
                                </span>
                                <span class="navi-text">Graduate students</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="academic_classes.length == 0">
                    <td class="px-3 text-center" colspan="6"><strong>Search for program to display classrooms here. Once they appear, you can graduate individual class</strong></td>
                  </tr>
                </template>
              </table>
              <b-pagination
                @input="getAcademicClasses"
                v-model="page"
                v-if="academic_classes.length > 0"
                :total-rows="total"
                :per-page="perPage"
                first-number
                last-number
                class="pull-right mt-7"
                :disabled="loading"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="dialog" persistent max-width="1600px">
      <v-card>
        <v-toolbar dark>
          <v-card-title class="headline">
            Select student who are qualified for graduation
            <hr />
          </v-card-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon @click="closeDialog">
              <i class="fas fa-times"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="graduate.completion_date"
                      label="Graduation date"
                      prepend-icon=""
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      dense
                      outlined
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="graduate.completion_date"
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row v-show="assigned_students.length > 0">
<!--              <span class="font-weight-bold h6">Students in this classroom</span>-->
              <v-col cols="12">
                <table class="table">
                  <tr>
                    <td colspan="2">Students in this classroom</td>
                  </tr>
                  <tr  v-for="(student, index) in assigned_students" :key="index">
                    <td class="" style="width: 40px !important; white-space: pre-wrap;">
                      <v-checkbox
                        v-model="graduate.students_ids"
                        :value="student.id"
                    ></v-checkbox></td>
                    <td>
                      <a href="#!" @click.prevent="openSummaryPage(item)" class="mb-1">
                        {{ student.full_name }}
                      </a>|
                      <a href="#!" @click.prevent="openSummaryPage(item)" class="mb-1">
                        <b> {{ student.crn_no }} </b>
                      </a>
                    </td>
                  </tr>
                </table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="btn btn-standard cancel-btn" depressed @click="closeDialog">
            Cancel
          </v-btn>
          <v-btn
            :loading="isLoading"
            class="text-white ml-2 btn btn-primary"
            depressed
            @click="graduatedClass"
            v-if="checkIsAccessible('academic-class', 'create') && checkIsAccessible('academic-class', 'edit')"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import ProgramService from "@/core/services/level/program/ProgramService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";
const gradeLevelService = new GradeLevelService();
const programService = new ProgramService();
const academicClass = new AcademicClassService();
const academicYearService = new AcademicYearService();
export default {
  data() {
    return {
      search: {
        academic_year: "",
        program_id: "",
        grade_id: "",
        status: "running",
        shift: "",
        name: "",
      },

      page: null,
      total: null,
      perPage: null,
      academic_classes: [],
      academicYears: [],

      programs: [],
      gradesLevels: [],
      loading: false,
      isLoading: false,
      menu: false,
      menu2: false,
      selectAll: false,
      selected: [],
      dialog: false,
      graduate: {
        class_id: null,
        completion_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        students_ids: [],
      },
      assigned_students: [],
    };
  },
  mounted() {
    this.getAcademicYears();
    this.getPrograms();
  },
  methods: {
    getAcademicClasses() {
      this.loading = true;
      academicClass
        .paginate(this.search, this.page)
        .then((response) => {
          this.academic_classes = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
        })
        .catch((err) => {})
        .finally(() => {
          this.loading = false;
        });
    },
    getPrograms() {
      programService.all().then((response) => {
        this.programs = response.data;
      });
    },
    getGrades() {
      gradeLevelService.getByProgram(this.search.program_id).then((response) => {
        this.gradesLevels = response.data;
      });
    },
    getAcademicYears() {
      academicYearService.paginate().then((response) => {
        this.academicYears = response.data.data;
      });
    },
    select() {
      this.selected = [];
      if (!this.selectAll) {
        for (let i in this.academic_classes) {
          this.selected.push(this.academic_classes[i].id);
        }
      }
    },
    openDialog(classId) {
      this.graduate.class_id = classId;
      this.getAssignedStudents(classId);
    },
    closeDialog() {
      this.dialog = false;
    },
    resetData() {
      this.graduate = {
        class_id: null,
        completion_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        students_ids: [],
      };
    },
    graduatedClass() {
      this.isLoading = true;
      this.graduate.class_ids = this.selected;
      academicClass
        .graduatedClassroom(this.graduate)
        .then((response) => {
          this.selected = [];
          this.graduate = {
            class_ids: [],
            completion_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
              .toISOString()
              .substr(0, 10),
          };
          this.closeDialog();
          this.resetData();
          this.getAcademicClasses();
          this.$snotify.success("Information updated");
        })
        .catch((err) => {
          // console.log(err)
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getAssignedStudents(classId) {
      this.$bus.emit('toggleLoader');
      this.graduate.students_ids = [];
      academicClass
        .getClassStudents(classId)
        .then((response) => {
          this.assigned_students = response.data.students;
          this.dialog = true;
          if (this.assigned_students.length > 0) {
            let lists = [];
            this.assigned_students.map(function (value, key) {
              lists.push(value.id);
            });
            this.graduate.students_ids = lists;
          }
        })
        .catch((err) => {})
        .finally(() => {
          this.$bus.emit('toggleLoader');
        });
    },
  },
};
</script>
